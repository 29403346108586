
import { useState, useEffect } from 'react';
import Amplify, { Auth } from 'aws-amplify';
import { navigate } from 'gatsby'
import amplifyConfig from '../amplify';

const getProfile = ({ idToken: { payload }}) => ({
  givenName: payload.given_name,
  name: payload.name,
  email: payload.email,
  username: payload['cognito:username'],
  role: payload['custom:role'],
  stores: payload['custom:stores'],
  tenantId: payload['custom:tenantid'],
});

const useAuth = () => {
  const [user, setUser] = useState();
  useEffect(() => {
    if (!user) {
      async function auth() {
        const config = amplifyConfig();
        Amplify.configure(config);
        try {
          const session = await Auth.currentSession();
          setUser(getProfile(session));
        } catch (e) {
          navigate('/login');
        }
      }
      auth();
    }
  }, [user]);

  return user;
}

export default useAuth;