import React, { useState } from 'react';
import { Link } from "gatsby"
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import VpnKeyIcon from '@material-ui/icons/VpnKey';

import TransitionAlert from './transition-alert';
import { useSearch } from './search-context';
import Loading from './loading';
import useFetchTenants from './use-fetch-tenants';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
  alert: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  noItems: {
    padding:  theme.spacing(8, 0),
    color: theme.palette.text.secondary,
    textAlign: 'center',
    display: 'block',
  }
}));

const sortTenantsByDescription = (a, b) => {
  if (a.description < b.description)
    return -1;
  if (a.description > b.description)
    return 1;
  return 0;
}

const getTenantsFiltered = (tenants = [], searchTerm) => {
  const re = new RegExp(searchTerm.toLowerCase());
  return tenants
    .filter(u => re.test(JSON.stringify([
      u.account,
      u.description,
    ]).toLowerCase()))
    .sort(sortTenantsByDescription);
}

const TenantsList = () => {
  const classes = useStyles();
  const initialDeleteTenant = { isDeleting: false, error: null, isSuccess: false };
  const [deleteTenant, setDeleteTenant] = useState(initialDeleteTenant);
  const [searchTerm] = useSearch();
  const { tenants, isLoading, isError, setTenants } = useFetchTenants();
  const tenantsFiltered = getTenantsFiltered(tenants, searchTerm);

  const handleCloseDeleteAlert = () => setDeleteTenant(initialDeleteTenant);

  if (isLoading) return <Loading />
  if (isError) return (
    <TransitionAlert severity="error" isOpen>
      There was an error fetching the tenants.
    </TransitionAlert>
  );

  return (
    <div>
      { deleteTenant.isSuccess && (
        <TransitionAlert severity="success" isOpen className={classes.alert} onClose={handleCloseDeleteAlert}>
          Tenant deleted successfully
        </TransitionAlert>
      )}
      { deleteTenant.error && (
        <TransitionAlert severity="error" isOpen className={classes.alert} onClose={handleCloseDeleteAlert}>
          { deleteTenant.error }
        </TransitionAlert>
      )}
      { !tenantsFiltered.length && <Typography className={classes.noItems}>There are no tenants yet</Typography> }
      <List className={classes.root}>
      { tenantsFiltered.map(tenant => (
        <React.Fragment key={tenant.id}>
          <ListItem alignItems="flex-start" button component={Link} to={`/tenants/edit?id=${tenant.id}`}>
            <ListItemAvatar>
              <Avatar>
                <VpnKeyIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={tenant.description}
              secondary={
                <React.Fragment>
                  {`@${tenant.account}`}
                </React.Fragment>
              }
            />
          </ListItem>
          <Divider variant="inset" component="li" />
        </React.Fragment>
      ))}
    </List>
    </div>
  );
}

export default TenantsList;