import { useState, useEffect } from 'react';
import { EP, callApi } from '../api';

const useFetchTenants = () => {
  const [tenants, setTenants] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        setIsLoading(true);
        const { results: tenants } = await callApi(EP.tenants.list);
        setIsLoading(false);
        setTenants(tenants);
      } catch (e) {
        console.log(e);
        setIsLoading(false);
        setIsError(true);
      }
    }
    fetchData();
  }, []);
  
  return { tenants, isLoading, isError, setTenants };
}

export default useFetchTenants;
