import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  title: {
    textTransform: 'capitalize',
  },
}));

export default function TransitionAlerts(props) {
  const { t } = useTranslation('common');
  const { 
    className,
    children,
    isOpen = false,
    onClose,
    severity = 'error',
   } = props;
  const classes = useStyles();
  const [open, setOpen] = useState(isOpen);
  useEffect(() => setOpen(isOpen), [isOpen]);
  return (
    <div className={classes.root}>
      <Collapse in={open}>
        <Alert
          className={className}
          severity={severity}
          onClose={onClose}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpen(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          <AlertTitle className={classes.title}>{t(`${severity}`)}</AlertTitle>
          {children}
        </Alert>
      </Collapse>
    </div>
  );
}
